import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {AutoLogoutService} from './services/auto-logout.service';
import {FnpDialogComponent, FnpDialogContent} from './views/shared/fnp-dialog/fnp-dialog.component';
import {DialogService} from './services/dialog.service';
import {environment} from '../config/environments/environment';
import {FNP_APPLICATION_VERSION} from './utils/other/constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public title = 'FordPro Angebote fuer Flotten';
    public dialogContent: FnpDialogContent;
    private readonly _autoLogoutService = inject(AutoLogoutService);
    private readonly _dialogService = inject(DialogService);

    // 13.12.2024 1PM CET
    private readonly APP_RELEASE_VERSION = FNP_APPLICATION_VERSION;

    @ViewChild('dialogComponent') public dialogTemplate: FnpDialogComponent;


    public ngOnInit(): void {
        console.log(`Currently running version: ${this.APP_RELEASE_VERSION}`);
        console.log(`Current environment setup: ${environment.name}`);
        this._dialogService.dialogState$.subscribe({
            next: (content: FnpDialogContent | null) => {
                if (content) {
                    if (this.dialogContent) {
                        if (this.dialogContent.override) return;
                        if (content.override) {
                            delete this.dialogContent;
                        }
                    }
                    this.dialogContent = content;
                    this.dialogTemplate.open();
                } else {
                    delete this.dialogContent;
                    this.dialogTemplate?.emitAndClose();
                }
            },
            error: (error) => console.log('11217',error)
        });
    }

}
