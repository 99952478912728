<table class="fnp__inner-table">
    <tr class="fnp__inner-table__tr">
        <th
                [ngStyle]="{'display':  (printing ? 'none' : 'table-cell') }"
                class="fnp__inner-table__tr__checkbox-th">
            <input
                    type="checkbox"
                    class="fnp-checkbox"
                    [name]="'toggleAllCheckbox_' + dataKeyword"
                    (click)="toggleAll()">
        </th>
        <th class="fnp__inner-table__th__width-11"><span>{{"FNP.MENU_NUMBER" | translate}}</span></th>
        <th
                class="fnp__inner-table__th"
                [ngClass]="isEdgeBrowser? 'fnp__inner-table__th--edge' : 'fnp__inner-table__th--default'"
        >
            <span>{{"FNP.JOB_DESCRIPTION" | translate}}</span>
        </th>
        <th *ngFor="let header of headers ?? ['FNP.PRICE']"
            class="fnp__inner-table__th__width-7">
            <span>{{ header | translate }}</span>
        </th>
    </tr>
    <tbody>
    <ng-container *ngIf="(jobErrorMsg || !data); then emptyTable; else validTable"></ng-container>
    <ng-template #emptyTable>
        <tr class="fnp__inner-table__body-tr">
            <td
                    class="placeholder-cell"
                    [colSpan]="headers?.length === 2 ?
                     2 + headers.length + (printing ? 0 : 1)
                     : 2 + (printing ? 5 : 6)">
                {{(jobErrorMsg ? jobErrorMsg : "FNP.SEARCH_FOR_JOB_INFORMATION") | translate}}
            </td>
        </tr>
    </ng-template>
    <ng-template #validTable>
        <tr class="fnp__inner-table__body-tr" *ngFor="let record of data; let index=index">
            <td
                    [ngStyle]="{'display' : (printing ? 'none' : 'table-cell'), 'width': (printing ? '0' : 'max-content')}"
                    class="fnp__inner-table__body-tr__checkbox-td">
                <input
                        type="checkbox"
                        class="fnp-checkbox"
                        [name]="'checkbox_'+ dataKeyword + '_' + record.fcjMenuNumber"
                        (click)="toggleSingle(record.fcjMenuNumber)">
            </td>
            <td>{{ record?.fcjMenuNumber }}</td>
            <td>{{ record?.jobDescription }}</td>
            <td
                    *ngIf="record?.region1Price"
                    class="fnp__inner-table__body-tr__price-td"
            >{{record?.region1Price | currency: currency}}</td>
            <td
                    *ngIf="record?.region2Price"
                    class="fnp__inner-table__body-tr__price-td"
            >{{record?.region2Price | currency: currency}}</td>
            <td
                    *ngIf="record?.region3Price"
                    class="fnp__inner-table__body-tr__price-td"
            >{{record?.region3Price | currency: currency}}</td>
        </tr>
    </ng-template>
    </tbody>
</table>