import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {ROLE} from '../enums/role';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {

    constructor(
        protected override readonly router: Router,
        private readonly keycloak: KeycloakService) {
        super(router, keycloak);
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {

        const authenticated: boolean | undefined = this.keycloak.getKeycloakInstance().authenticated;

        if (!authenticated) {
            const langParam = document?.location?.href?.            // get url
            split('?')?.[1]?.                                       // get queryParams
            split('#')?.[0]?.                                       // exclude error message
            split('&')?.                                            // split queryParams
            find(el => el?.startsWith('lang='))?.                   // find language param
            replace('lang=', '') ?? 'de';                           // extract language code or set de as default
            console.log(`l->${langParam}`);
            await this.keycloak.login({
                redirectUri: window.location.origin + state.url,
                locale: langParam
            }).catch(e => console.error(e));
        }
        const minRequiredRole: string = route.data['role'];

        if (!minRequiredRole) {
            console.log('41311');
            return true;
        }

        if(minRequiredRole.toLowerCase() === ROLE.ADMIN.toLowerCase()) {
            console.log('17112');
        }



        return minRequiredRole.toLowerCase() === ROLE.ADMIN.toLowerCase() ?
            this.roles.findIndex(role => role.toLowerCase() === minRequiredRole.toLowerCase()) !== -1
            : this.roles.findIndex(role => role.toLowerCase() === minRequiredRole.toLowerCase()
            || role.toLowerCase() === ROLE.ADMIN.toLowerCase()) !== -1;

    }
}
