import {inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, from, Observable, switchMap, throwError} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {AuthService} from '../../services/auth.service';
import {DialogService} from '../../services/dialog.service';

@Injectable()
export class UnAuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private _authService: AuthService = inject(AuthService);
    private _dialogService: DialogService = inject(DialogService);
    private _kcService: KeycloakService = inject(KeycloakService);

    intercept(request: HttpRequest<unknown>, next: HttpHandler):  Observable<HttpEvent<unknown>>  {
        const originReq = request;
        return next.handle(request).pipe(catchError((e) => {
            console.log('21121',e);
            if (e instanceof HttpErrorResponse && e.status === 401) {
                console.log('21122',e);
                return this.handle401Error(originReq, next);
            }
            return throwError(() => e);
        }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler):  Observable<HttpEvent<unknown>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            return from(this._kcService.updateToken()).pipe(
                switchMap(() => {
                    this.isRefreshing = false;
                    return next.handle((request));
                }),
                catchError((e) => {
                    this.isRefreshing = false;
                    this.logoutFromExpiredSession();
                    return throwError(() => e);
                })
            );
        }
        return next.handle(request);
    }

    private logoutFromExpiredSession(): void {
        const dialogContent = this._dialogService.getNewDialogContent('error', 'ERROR.EXPIRED_SESSION', null, 'ERROR.EXPIRED_SESSION_MSG');
        dialogContent.override = true;
        this._dialogService.openInfoDialog(dialogContent);
        setTimeout(() => this._authService.logout(), 2500);
    }
}
